<template>
    <v-container class = "ma-0 pa-0" fluid fill-height>
        <div id = "map">

          <v-btn
            class = "mapviewerBtn ma-5 primary" 
            elevation="2"
            color="#0082ba"
            @click="goToPage('/Mapviewer')"
          ><v-icon
                dark
                left
              >
                mdi-map-legend
          </v-icon>View Full Map</v-btn>


           <!-- <v-btn
            class = "mapviewerBtn ma-10 primary" 
            elevation="2"
            color="#0082ba"
            @click="consoleSumtin()"
          ><v-icon
                dark
                left
              >
                mdi-map-legend
          </v-icon>Console Log</v-btn> -->
       


        <div id = "dashboard" v-show="!displayOne">
           <template>
                <v-data-table
                  :headers= headers2
                  :items= tableContents
                  item-key="name"
                  class="elevation-1  dashboard_table"
                  :items-per-page = -1
                  :disable-pagination = true
                  height="93vh"
                  :disable-filtering= true
                  :hide-default-footer="true"
                  :hide-default-header="true"




                  
                >

                 <template v-slot:header="{ props }">
                  <thead class = "headers-class">
                  <tr>
                     <th   v-for="(item,key) in props.headers" :key="key" class="text-start td-class" @click="displayStatInfo(item.value);">  {{ item.text}}</th>
                  </tr>
                  </thead>
                </template>
                

                 <template v-slot:body="{ items }">
                  <tbody class="text-center">
                  <tr  v-for="(item,key) in items" :key="key" :class="item.class" >
                    <template v-if = "item.parameter == 'Water Quality Index'">
                         <td  class="text-start">  {{ item.parameter }}  </td>
                         <template  v-for="(nestedItem, nestedKey) in getArray(item)" >
                           <td  class="text-start" :key="nestedKey" >
                            <v-chip
                            color = nestedItem.class
                            >{{nestedItem.value}}
                            </v-chip> 
                           </td>
                         </template>
                         
                           <!-- <td  class="text-start"> lalaala </td>
                            <td  class="text-start"> lalaala </td>
                             <td  class="text-start"> lalaala </td> -->
                            
                      
                      <!-- <template  v-for="(nestedItem, nestedKey) in item" >
                      <td   v-if= "nestedKey!= 'class'" :key="nestedKey" class="text-start">  {{ nestedItem }} </td>
                      </template> -->

                    </template>
                    <template v-else >
                      <template  v-for="(nestedItem, nestedKey) in item" >
                      <td   v-if= "nestedKey!= 'class'" :key="nestedKey" class="text-start">  {{ nestedItem }} </td>
                      </template>
                    </template>
                  </tr>
                  </tbody>
                </template>
                
                </v-data-table>
              </template>
        </div>

        <div id = "stationInfo" v-show = "displayOne" >
          <template >
              <v-card
                class="mx-auto my-12 station-info" 
                width="600"
              >
                <template slot="progress" >
                  <v-progress-linear
                    color="deep-purple"
                    height="10"
                    indeterminate
                  ></v-progress-linear>
                </template>

                <template>
                    <v-carousel
                      cycle
                      height="400"
                      hide-delimiter-background
                      show-arrows-on-hover
                    >
                      <template v-slot:prev="{ on, attrs }">
                        <v-btn
                          color="success"
                          v-bind="attrs"
                          v-on="on"
                        >Previous slide</v-btn>
                      </template>
                      <template v-slot:next="{ on, attrs }">
                        <v-btn
                          color="info"
                          v-bind="attrs"
                          v-on="on"
                        >Next slide</v-btn>
                      </template>
                     <v-carousel-item
                        v-for="(item,i) in stationPicture"
                        :key="i"
                        :src="item.src.replace('sarawakdir','publicdir')"
                      ></v-carousel-item>
                    </v-carousel>
                  </template>

                <v-card-title @click="displayStatInfo()"> {{selectedStationInfo.stationId}} </v-card-title>

                <v-card-text>
                  <v-row
                    align="center"
                    class="mx-0 pa-0"
                  >
                    
                  <v-col cols="12" class="mx-0 pa-0">
                  <div>Class : Class {{selectedStationInfo.stationId}}</div>
                  </v-col>
                  <v-col cols="12" class="mx-0 pa-0">
                  <div>Location : {{selectedStationInfo.location}}</div>
                  </v-col>
                  <v-col cols="12" class="mx-0 pa-0">
                  <div>Last Updated : {{selectedStationInfo.datetime}}</div>
                  </v-col>
                  </v-row>
                </v-card-text>

                <v-divider class="mx-4"></v-divider>

                <v-card-title>
                  <template>
                      <div>
                        <div class="d-flex" style="width: 460px">
                        </div>

                        <v-expansion-panels
                          v-model="panel"
                          multiple
                        >
                          <v-expansion-panel>
                            <v-expansion-panel-header>WQI Parameters</v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <template>
                                    <v-data-table
                                      item-key="name"
                                      class="elevation-1 mb-2 "
                                      :items-per-page = -1
                                      :disable-pagination = true
                                      height="93vh"
                                      :disable-filtering= true
                                      :hide-default-footer="true"
                                      :hide-default-header="true"
                                      style="overflow-y:scroll;"

                                    >

                                    <template v-slot:header>
                                      <thead class = "headers-class">
                                      <tr>
                                        <th> Parameter</th>
                                        <th> Value & Unit</th>
                                        <th> Sub Index</th>
                                      </tr>
                                      </thead>
                                    </template>
                                    

                                    <template v-slot:body>
                                      <tbody class="text-center">
                                      <tr  v-for="(nestedItem, nestedKey) in selectedStationInfo.parameter"  :key="nestedKey">
                                         <template>
                                           <td class="text-start">  {{ nestedItem.name }} </td>
                                            <td class="text-start">  {{ nestedItem.value + " " + nestedItem.unit}} </td>
                                             <td class="text-start">  {{ nestedItem.si}} </td>
                                        </template>
                                      </tr>
                                      </tbody>
                                    </template>
                                    
                                    </v-data-table>
                                </template>
                            </v-expansion-panel-content>
                          </v-expansion-panel>

                          <v-expansion-panel>
                            <v-expansion-panel-header>Station Details</v-expansion-panel-header>
                            <v-expansion-panel-content>
                              Some content
                            </v-expansion-panel-content>
                          </v-expansion-panel>


                        </v-expansion-panels>
                      </div>
                    </template>
                </v-card-title>

                <!-- <v-card-text>
                  <v-chip-group
                    v-model="selection"
                    active-class="deep-purple accent-4 white--text"
                    column
                  >
                    <v-chip>5:30PM</v-chip>

                    <v-chip>7:30PM</v-chip>

                    <v-chip>8:00PM</v-chip>

                    <v-chip>9:00PM</v-chip>
                  </v-chip-group>
                </v-card-text>

                <v-card-actions>
                  <v-btn
                    color="deep-purple lighten-2"
                    text
                  >
                    Reserve
                  </v-btn>
                </v-card-actions> -->
              </v-card>
            </template>
        </div>
    </div>
    </v-container>
</template>

<script>

import "ol/ol.css";


import Map from "ol/Map";
import View from "ol/View";
import { defaults as defaultControls, ScaleLine } from "ol/control";
import {
  Tile as TileLayer,
  Vector as VectorLayer,
  Group as GroupLayer,
} from "ol/layer";
import { OSM, Vector as VectorSource } from "ol/source";
import { Fill, Stroke, Circle, Style, Icon } from "ol/style";
import Feature from "ol/Feature";
import { fromLonLat } from "ol/proj";
import { Point } from "ol/geom";
import Overlay from "ol/Overlay";
import {getBottomLeft, getTopRight} from 'ol/extent.js';


// Axios for fetching data
import axios from "axios";


export default {
    
    data: () => ({

    // Map Declaration
    map: null,
    view: null,
    extent: [ 100.35722893500143, 3.0516507132748085, 104.08900688046693, 5.8267903441487565 ],
    baseMapLayer: {
      topographicMap: new TileLayer({
        title: "Topographic Map",
        source: new OSM({
          url: "http://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}.png",
          crossOrigin: "anonymous",
        }),
        visible: false,
        type: "base",
      }),
      imageryMap: new TileLayer({
        title: "Imagery Map",
        source: new OSM({
          // "url" : 'http://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}',
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
          // "url": 'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
          crossOrigin: "anonymous",
        }),
        visible: false,
        type: "base",
      }),
      streetMap: new TileLayer({
        title: "OSM",
        source: new OSM({
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
          crossOrigin: "anonymous",
        }),
        visible: false,
        type: "base",
      }),
      googleStreetMap: new TileLayer({
        title: "Google Street",
        source: new OSM({
          url: "https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}",
          // "url": 'http://mt1.googleapis.com/vt?x={x}&y={y}&z={z}',
          crossOrigin: "anonymous",
        }),
        visible: true,
        type: "base",
      }),
      googleSateliteMap: new TileLayer({
        title: "OSM",
        source: new OSM({
          url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
          crossOrigin: "anonymous",
        }),
        visible: false,
        type: "base",
      }),
    },
    pointStyle: {
      default: new Style({
        image: new Circle({
          fill: new Fill({
            color: "#3381ff",
          }),
          stroke: new Stroke({
            color: "#0242ab",
            width: 1.25,
          }),
          radius: 8,
        }),
      }),
      selected: new Style({
        image: new Circle({
          fill: new Fill({
            color: "#00ffff",
          }),
          stroke: new Stroke({
            color: "#003300",
            width: 1.25,
          }),
          radius: 8,
        }),
      }),
    },

    //Table Data



        headers2 : [ {
            text: 'Parameters',
            align: 'start',
            sortable: false,
            value: 'parameter',
          },],


          listParamDisplayed : [ "WQI", "TEMPERATURE", "pH","DO_SAT", "DO_CON", "EC", "SALINITY", "NH4", "NH3N", "TURBIDITY", "TSS", "BOD", "COD", "DOC", "TOC", "E_COLI", "CHLOROPHYLL_A", "SOLAR_BATTERY", "TRYPHPTOPHAN", "CDOM" ],

          paramContentDetail: [],
          stationDetails: [],
          tableContents: [],

          station_status: [
            {
              text: "Active",
              img: require("@/assets/mapviewer/station_icon_green.svg"),
            },
            {
              text: "Inactive",
              img: require("@/assets/mapviewer/station_icon_grey.svg"),
            },
            {
              text: "Transmission Delay",
              img: require("@/assets/mapviewer/station_icon_orange.svg"),
            },
          ],

          card_station_name: "111",
          selection: "111",
          panel: "",
          displayOne:  false,
          stationInfoList: [],
          selectedStationInfo: {},

          stationPicture: [],
          

    
    }),
    methods: {

        initiateMap() {
            var source = new VectorSource();
            var vector = new VectorLayer({
                source: source,
            });

            var baseMap = new GroupLayer({
                layers: [
                this.baseMapLayer.topographicMap,
                this.baseMapLayer.imageryMap,
                this.baseMapLayer.streetMap,
                this.baseMapLayer.googleStreetMap,
                this.baseMapLayer.googleSateliteMap,
                ],
            });

            this.map = new Map({
                controls: defaultControls({
                zoom: false,
                }),
                // .extend([
                //     new ScaleLine({
                //         units: 'metric',
                //     }),
                // ]),
                target: "map",
                layers: [baseMap],
                view: new View({
                projection: "EPSG:4326",
                zoom: 1,
                }),
            });

            this.view = this.map.getView();
            this.view.fit(this.extent);
            this.map.on('moveend', this.onMoveEnd)
            },
            

            getColor (calories) {
                if (calories > 400) return 'red'
                else if (calories > 200) return 'orange'
                else return 'green'
            },

            getStationInfo() {
                  axios
                    .get("https://apis.spatialworks.com.my/sarawak/cms/reading", {
                      headers: {
                        Authorization: "Bearer " + this.$store.getters.user.token,
                      },
                    })
                    .then((response) => {
                      this.stationDetails = response.data;
                      // this.addMapMarker();

                      // console.log(this.stationDetails);

                      this.genTableHeader();

                      // let stationData = response.data;
                      // this.stationList = [];
                      // for(let i = 0; i < stationData.length; i++){
                      //   this.stationList.push(stationData[i].stationid_a);

                        // for(let j = 0; j < this.headers.length; j++){
                        //   if(this.headers[j].value == stationData[i].stationid_a){
                        //     this.headers[j].name = stationData[i].LOCATION;
                        //     this.headers[j]['last_date'] = stationData[i].DATETIME;
                        //   }
                        // }
                      })
                     .catch((error) => {
                        console.log(error);
                      });


            },


            getParamInfo() {

                axios
                  .get("https://apis.spatialworks.com.my/sarawak/cms/params", {
                    headers: {
                      Authorization: "Bearer " + this.$store.getters.user.token,
                    },
                  })
                  .then((response) => {
                    this.paramDetails = response.data;

                      // console.log(this.paramDetails);

                      this.genParamList();

                    })
                    .catch((error) => {
                      console.log(error);
                });

            },

            genTableHeader() {

              // console.log('fuhh',this.stationDetails);

              for (let i = 0; i < this.stationDetails.length; i++){


                let headerName = {
                  text: this.stationDetails[i].STATION_ID + "\n" + this.stationDetails[i].LOCATION + " " +this.stationDetails[i].STATE_NAME + " " + this.stationDetails[i].DATETIME ,
                  value: this.stationDetails[i].STATION_ID 
                  
                }


                
                this.headers2.push(headerName)

              }

              this.getParamInfo();




            },


            genParamList() {


              for(let j = 0; j < this.listParamDisplayed.length; j++){

                  for(let i = 0; i < this.paramDetails.length; i++){

                                      
                    if (this.listParamDisplayed[j] == this.paramDetails[i].reading){
                        // console.log("listParamDisplayed", this.listParamDisplayed[j], this.paramDetails[i].reading)

                        Object.keys(this.paramDetails[i]).forEach(key => {
                          if (this.paramDetails[i][key] === null) {
                            this.paramDetails[i][key] = '';
                          }
                        });
                        
                        let paramContent = {
                            name: this.listParamDisplayed[j],
                            fullName: this.paramDetails[i].fullname,
                            unit: this.paramDetails[i].unit
                        }


                        
                        this.paramContentDetail.push(paramContent);

                    }

                  }

                  
              }

                  // console.log("syationdeta", this.stationDetails);

              this.genTable();
            },


            genTable(){

                                  //  console.log("tableContent", this.stationDetails)

                this.getStationInfoList(); // GET CONTENT FOR STATION INFO


                for(let i = 0; i < this.paramContentDetail.length; i++) {         

                    let parameter = this.paramContentDetail[i].name;
                    let tableContent = {
                        parameter : this.paramContentDetail[i].fullName,
                        class : 'datatable-class'
                        
                    }	

                    // let stationDetailsParse =  JSON.stringify(this.stationDetails);
                    
                     for (let j = 0; j < this.stationDetails.length; j++){

                        

                        //REPLACE NULL VALUES TO Na

                        Object.keys(this.stationDetails[j]).forEach(key => {
                            // console.log("hehehe", this.stationDetails[j][key]);

                          if (this.stationDetails[j][key] === null) {
                            this.stationDetails[j][key] = 'NA';
                          }
                        });

                        let tempValue = this.stationDetails[j][parameter]


                        // let tempValue = stationDetailsParse


                        // console.log("temp", tempValue)
                        // tempValue = tempValue.replace(null, "NA");
                        if (this.paramContentDetail[i].name == "WQI"){
                            
                            let tempObj = {
                              value: this.stationDetails[j]["param_wqi"] ,
                              class: this.stationDetails[j]["param_wqi_class"] 
                            }

                            tableContent[this.stationDetails[j].STATION_ID] = tempObj

                        }
                        else {

                          if (tempValue !== "NA")
                            tableContent[this.stationDetails[j].STATION_ID] = tempValue+ " " + this.paramContentDetail[i].unit;
                          else
                            tableContent[this.stationDetails[j].STATION_ID] = tempValue
                        }


                        

                        
                     }

                    this.tableContents.push(tableContent)
                    // console.log(this.tableContents);

                }


                
            },

            setRowStyle(item) {
                /* Here you can also do some validation
                in case you want to apply different css 
                classes depending on item attributes 
                values */
                return 'style-1'
            },


            consoleSumtin() {
              // console.log(JSON.stringify(this.tableContents))
              this.addMapMarker();

              // console.log((this.tableContents));
              
              this.getStationInfoList()
            },

            getArray(item) {
              delete item.parameter;
              delete item.class
              
              return item
            },


            addMapMarker() {
              for (let i = 0; i < this.stationDetails.length; i++) {
                var marker = new Feature({
                  geometry: new Point([
                    this.stationDetails[i].LONGITUDE,
                    this.stationDetails[i].LATITUDE,
                  ]),
                  description: this.stationDetails[i],
                });
                var vectorSource = new VectorSource({
                  features: [marker],
                });

                var markerVectorLayer = new VectorLayer({
                  source: vectorSource,
                  style: new Style({
                    image: new Icon({
                      crossOrigin: "anonymous",
                      src: this.stationDetails[i].green == 'true' ? this.station_status[0].img : this.stationDetails[i].grey == 'true' ? this.station_status[1].img : this.stationDetails[i].yellow == 'true' ? this.station_status[2].img : '',
                      scale: 1.5,
                    }),
                  }),
                });
                this.map.addLayer(markerVectorLayer);
              }
              // this.addMapOverlayReading();
            },

            display(id, value) {
                console.log(id, value)
              },

             wrapLon(value) {
                const worlds = Math.floor((value + 180) / 360);
                return value - worlds * 360;
              },

            onMoveEnd(evt) {

              // console.log(this.map);
              // console.log(this.view);

                const map = evt.map;
                // const extent = this.map.getView().calculateExtent(map.getSize());
                // const bottomLeft = fromLonLat(getBottomLeft(extent));
                // const topRight = fromLonLat(getTopRight(extent));
                // this.display('left', this.wrapLon(bottomLeft[0]));
                // this.display('bottom', bottomLeft[1]);
                // this.display('right', this.wrapLon(topRight[0]));
                // this.display('top', topRight[1]);
                // this.display("bottomLeft", fromLonLat(getBottomLeft(extent)))

                // // const extent2 =  this.map.zoomExtent(extent2)
                this.display("extent2", map.getView().calculateExtent())
            },

            displayStatInfo(statInfo) {
              
              this.getSelectedStationInfo(statInfo);     
              this.displayOne = !this.displayOne
            },

            getStationInfoList(){

              let listParam = []
              let keys
              for(let i = 0; i < this.stationDetails.length ; i++){
                  keys = Object.keys(this.stationDetails[i]);
              }

              //get all properties that include param string
              const allParamKeys= keys.filter((keys) => {
                return keys.includes('param') ;
              });

              //get all paramaters without sub index and class
              let paramWithoutSiAndClass = allParamKeys.filter((allParamKeys) => {
                return !allParamKeys.includes('si') &&  !allParamKeys.includes('class') ;
              });

              //get all parameters name only
              let parameters = allParamKeys.filter((paramWithoutSiAndClass) => {
                return !paramWithoutSiAndClass.includes('si') &&  !paramWithoutSiAndClass.includes('class') ;
              });

              

               parameters.forEach(function (param,index) { 
                  console.log("huww", parameters[index]);
                  parameters[index] = parameters[index].replace("param_", "")
               })


              console.log("paramDetails", JSON.stringify(this.paramDetails))
              console.log("parameterd", parameters)
              console.log("stationDetails", JSON.stringify(this.stationDetails))


    
              for(let i = 0; i < this.stationDetails.length ; i++){
                
                
                let paramList = []

                // for(let k = 0; k < parameters.length ; k++){

                  let paramObj = {}

                  for(let j = (this.listParamDisplayed.length-1); j > -1 ; j--){
                    for(let m = 0; m < this.paramDetails.length ; m++){

                        
                      if (this.listParamDisplayed[j] == this.paramDetails[m].reading){
                        
                         paramObj = {
                          name: this.paramDetails[m].fullname,
                          value: this.stationDetails[i][this.listParamDisplayed[j]],
                          unit: this.paramDetails[m].unit,
                          si: this.stationDetails[i]["param_si_"+(this.listParamDisplayed[j]).toLowerCase()],
 
                          }

                          console.log( "param_si_"+(this.listParamDisplayed[j]).toLowerCase(),this.stationDetails[i]["param_si_"+(this.listParamDisplayed[j]).toLowerCase()]);

                          if(parameters.includes(this.listParamDisplayed[j].toLowerCase()))
                            paramList.unshift(paramObj)  
                          else
                            paramList.push(paramObj)  
                            


                      }
                      
                  }

                }

                  let stationInfoObj = {
                    stationId: this.stationDetails[i].STATION_ID,
                    location: this.stationDetails[i].LOCATION,
                    datetime: this.stationDetails[i].datetime_a,
                    longitude: this.stationDetails[i].LONGITUDE,
                    latitude: this.stationDetails[i].LATITUDE,
                    station_picture: this.stationDetails[i].STATION_PICTURE,
                    deployment_picture: this.stationDetails[i].DEPLOYMENT_PICTURE,
                    upstream_picture: this.stationDetails[i].UPSTREAM_PICTURE,
                    downstream_picture: this.stationDetails[i].DOWNSTREAM_PICTURE,
                    parameter: paramList
                  }

                  this.stationInfoList.push(stationInfoObj);
              }                                                
     
            },

            getSelectedStationInfo(statID) {
              
              this.stationPicture = []

              console.log("stat", statID)
              
              for(let i = 0 ; i < this.stationInfoList.length; i++){
                // console.log(this.stationInfoList[i].stationId, statID);
                if(this.stationInfoList[i].stationId == statID){
                  this.selectedStationInfo = this.stationInfoList[i];
                }
              }

              let keys = Object.keys(this.selectedStationInfo);
              //get all properties that include param string
              let arrParam = keys.filter((keys) => {
                return keys.includes('picture') ;
              });

              for(let i =0; i < arrParam.length; i++ ){
                  let obj = {
                    src: this.selectedStationInfo[arrParam[i]]
                  }

                  this.stationPicture.push(obj)
              }

              console.log(this.selectedStationInfo);

            }
            






    },
    mounted() {

        this.initiateMap();

        this.getStationInfo();



    },
}

</script>

<style scoped>

.whole-page {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#map {
    width: 100%;
    height: 100%;

}

#dashboard {
    position: absolute;
    width: 60%;
    height: 100%;
    z-index: 5;
    left:40%;

}

#stationInfo {
    position: absolute;
    width: 50%;
    height: 90% !important;
    z-index: 5;
    left: 53%;
    top: 10px;

}

.mapviewerBtn {
  position: absolute;
  left: 10px;
  z-index: 5;
}

/* table.v-table thead tr  {
  height: 100px !important;
  color: red !important;
} */

.dashboard_table > .v-data-table__wrapper > table > .v-data-table-header > tr > th {
  color: #276173 !important;
  background: p;
}


.td-class {
   background-color: #eceded;
   color: #276173 !important;
  }


  tbody tr:nth-of-type(even) {
    background-color: rgba(236, 237, 237) !important;
  }

  tbody tr:nth-of-type(odd) {
    background-color: rgb(250 ,250, 250);
  }

.station-info-table {
  overflow: scroll;
}

.td-header {
background: rgb(0,212,255);
background: linear-gradient(90deg, rgba(0,212,255,1) 3%, rgba(0,140,189,1) 56%, rgba(0,139,188,1) 63%, rgba(0,138,187,1) 83%, rgba(0,131,179,1) 90%, rgba(0,127,175,1) 97%, rgba(2,0,36,1) 100%, rgba(1,79,123,1) 100%, rgba(1,99,145,1) 100%); }
</style>